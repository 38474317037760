import { useTranslation } from "react-i18next";
import Obfuscate from "react-obfuscate";

import PropTypes from "prop-types";

import classnames from "classnames";

import { emailExtractor } from "../../helpers/emailExtractor";
import { getLocalizedUrl } from "../../helpers/localized-url";
import { mailToValidator } from "../../helpers/mailToValidator";
import Link from "../Link/Link";

import styles from "./ButtonLink.module.scss";

const ButtonLink = ({
  to,
  styleVariant,
  displayBlock,
  children,
  className,
  localized,
  target,
}) => {
  const { i18n } = useTranslation();

  const fullClassname = classnames(
    styles.buttonLink,
    styles[styleVariant],
    {
      [styles.displayBlock]: displayBlock,
    },
    className
  );

  const linkTo = localized ? getLocalizedUrl(i18n.language, to) : to;

  const isValidEmail = mailToValidator(to);

  return isValidEmail ? (
    <Obfuscate
      email={emailExtractor(to)}
      className={fullClassname}
      style={{ display: "inline-block" }}
    >
      {children}
    </Obfuscate>
  ) : (
    <Link to={linkTo} className={fullClassname} target={target}>
      {children}
    </Link>
  );
};

export default ButtonLink;

ButtonLink.propTypes = {
  to: PropTypes.string,
  styleVariant: PropTypes.oneOf([
    "black",
    "white",
    "transparent",
    "transparent--dark",
  ]),
  displayBlock: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  localized: PropTypes.bool,
  target: PropTypes.string,
};

ButtonLink.defaultProps = {
  styleVariant: "black",
  displayBlock: false,
  children: "A link",
  localized: false,
  target: "_self",
};
